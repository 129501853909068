<template>
  <page-container>
    <div class="layout">
      <div class="search_box">
        <el-form :inline="true" label-width="auto" :model="search_form">
          <el-form-item label="名称">
            <el-input placeholder="请输入工程/施工单位/建设单位名称" v-model="search_form.firmLongName" class="form_input" prop="firmLongName">
              <el-button slot="append" icon="el-icon-search"></el-button>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="submitSearch" :loading="loading">{{searchText}}</el-button>
          </el-form-item>
        </el-form>
      </div>
      <BaseTable
        id="mortarProd"
        ref="xTable"
        class="xtable-style ."
        border="inner"
        align="center"
        :header-cell-style="{'background-color':  '#F9FAFE'}"
        :data="tableData"
        highlight-hover-row
        :seq-config="{startIndex: (tablePage.currentPage - 1) * tablePage.pageSize}"
        :pager-config="tablePage"   @page-change="handlePageChange"
        :expand-config="{visibleMethod: expandVisibleMethod,iconOpen: 'el-icon-remove-outline', iconClose: 'el-icon-circle-plus-outline'}">
        <!--                <vxe-table-column type="checkbox" width="60"></vxe-table-column>-->
        <vxe-table-column type="expand" width="60">
          <template #default>
          </template>
          <template #content="{ row }">
            <div class="expand-wrapper">
              <vxe-table ref="qTable" :data="row.queuList" class="qtable-style" border="none"
                         align="left"
                         :header-cell-style="{'background-color':  '#F0F9EB','height':'30px','padding':'0'}"
                         :cell-style="{'background-color':  '#F0F9EB','height':'30px','padding':'0'}"
                         :row-class-name="querowClassName">
                <vxe-table-column field="productQueueName" title="生产线" show-overflow="title" min-width="60"/>
                <vxe-table-column field="dayWeight" title="当日产量（方）" show-overflow="title" min-width="60" />
                <vxe-table-column field="monthWeight" title="当月产量（方）" show-overflow="title" min-width="60" />
                <vxe-table-column field="createDateTime" title="最后上报时间" show-overflow="title" min-width="60"/>
                <vxe-table-column field="gkDeviceStatus" title="状态" show-overflow="title" min-width="100" :formatter="formatterOnline"></vxe-table-column>
              </vxe-table>
            </div>
          </template>
        </vxe-table-column>
        <vxe-table-column type="seq" width="60" title="序号"></vxe-table-column>
        <vxe-table-column field="compName" title="企业名称" show-overflow="title" width="200"/>
        <vxe-table-column field="dayWeight" title="工控机当日产量（方）" show-overflow="title" min-width="60"/>
        <vxe-table-column field="createDateTime" title="工控机当日最后上报时间" show-overflow="title" min-width="60"/>
        <vxe-table-column field="monthWeight" title="工控机当月产量（方）" show-overflow="title" min-width="60"/>
        <vxe-table-column field="createMonthDateTime" title="工控机月产量最后上报时间" show-overflow="title" min-width="60"/>
        <vxe-table-column field="upleDayWeight" title="直报当日产量（方）" show-overflow="title" min-width="60"/>
        <vxe-table-column field="upleDayCreateTime" title="直报当日产量上报时间" show-overflow="title" min-width="60"/>
        <vxe-table-column field="upleMonthWeight" title="直报当月产量（方）" show-overflow="title" min-width="60"/>
        <vxe-table-column field="upleMonthCreateTime" title="直报当月产量数据上报时间" show-overflow="title" min-width="60"/>
        <vxe-table-column title="查看视频" show-overflow="title" min-width="100">
          <template v-slot="{ row }">
            <span class="view_monitor" @click="watchVedio(row)">查看监控</span>
          </template>
        </vxe-table-column>
      </BaseTable>
      <vxe-pager
        :loading="loading"
        :current-page.sync="tablePage.currentPage"
        :page-size.sync="tablePage.pageSize"
        :total="tablePage.total"
        :layouts="['Sizes','PrevPage','JumpNumber','NextPage','FullJump','Total']"
        @page-change="handlePageChange"
      />
      <el-dialog
        :visible.sync="iframeDialog"
        width="80%"
        height="100%"
        :v-if="iframeDialog"
        :append-to-body="true"
        center
        v-dialogDrag
        :close-on-click-modal="false"
        :close-on-press-escape="false">
        <iframe :src="vedioUrl" frameborder="0" width="100%" height="600px" v-if="iframeEnabled"></iframe>
      </el-dialog>
    </div>
  </page-container>
</template>

<script>
import { getMortarProductionStatistics } from '@/apis/prod_manage/mortar_prod'
import { gainMortarAllVideoConfig } from '@/apis/prod_manage/common'
import moment from 'moment'

export default {
  name: 'stat',
  data () {
    return {
      searchText: '查询',
      allAlign: 'center',
      search_form: {
        firmLongName: ''
      },
      formLabel: '浙江网泽科技有限公司400条',
      alert: 0, // 离线计数
      todayMissing: 0, // 未上线计数,
      tablePage: {
        total: 0,
        currentPage: 1,
        pageSize: 30,
        align: 'right',
        pageSizes: [10, 20, 50, 100, 200, 500],
        layouts: ['Sizes', 'PrevJump', 'PrevPage', 'Number', 'NextPage', 'NextJump', 'FullJump', 'Total'],
        perfect: true
      },
      tableData: [],
      iframeDialog: false,
      iframeEnabled: false,
      vedioUrl: '',
      loading: false
    }
  },
  methods: {
    // 分页变化
    handlePageChange ({ currentPage, pageSize }) {
      this.tablePage.currentPage = currentPage
      this.tablePage.pageSize = pageSize
      this.refreshData()
    },
    submitSearch () {
      this.tableData = []
      // this.currentBusIndex = 0;
      this.tablePage.currentPage = 1
      this.refreshData()
    },
    expandVisibleMethod ({ row }) {
      if (row.queuList.length < 1) {
        return false
      }
      return true
    },
    watchVedio (row) {
      this.getIframeModal('preview')
    },
    getIframeModal (dest) {
      this.iframeEnabled = false
      gainMortarAllVideoConfig(this.companyParams).then(res => {
        if (res.code == 200) {
          const hikvisionConfig = res.data
          const param = '&account=' + hikvisionConfig.loginName + '&pass=' + hikvisionConfig.password
          this.vedioUrl = hikvisionConfig.url + '&dest=' + dest + param
          if (hikvisionConfig.iframe === '1') {
            window.open(this.vedioUrl)
          } else {
            this.iframeEnabled = true
            this.iframeDialog = true
          }
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    },
    querowClassName ({ row }) {
      const gkDeviceStatus = row.gkDeviceStatus
      if (gkDeviceStatus == 1 || gkDeviceStatus == 0) {
        return 'row-red'
      } else {
        const createDateTime = row.createDateTime
        if (gkDeviceStatus == 3 || !createDateTime || moment(createDateTime).isBefore(moment().subtract(row.longWarnIntervalTime, 'minutes'))) {
          return 'row-orange'
        }
      }
      return '上线中'
    },
    formatterOnline ({ cellValue, row, column }) {
      const gkDeviceStatus = row.gkDeviceStatus
      if (gkDeviceStatus == 1 || gkDeviceStatus == 0) {
        return '离线'
      } else {
        const createDateTime = row.createDateTime
        if (gkDeviceStatus == 3 || !createDateTime || moment(createDateTime).isBefore(moment().subtract(row.longWarnIntervalTime, 'minutes'))) {
          return '超过' + row.longwarnIntervalTime + '分钟未上报数据'
        }
      }
      return '上线中'
    },
    refreshData () { // todaymissing,alert单独处理
      this.loading = true
      getMortarProductionStatistics({
        compName: this.search_form.firmLongName,
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize
      }
      ).then(res => {
        this.loading = false
        if (res.code == 200) {
          const data = res.data
          this.tableData = data.rows
          this.tablePage.total = data.totalSize
        } else {
          this.tableData = []
          this.tablePage.total = 0
        }
      }).catch(error => {
        console.log(error)
        //  this.logining = false;
      })
    }

  },
  mounted () {
    this.refreshData()
  }
}
</script>

<style lang="scss" scoped>
.layout{
  background-color: white;
  .search_box{
    padding:24px;
    .search_head{
      font-weight: bold;
      color:#1F1F23;
    }
  }
  .form_input{
    width:400px;
  }
  .expand-wrapper {
    padding: 0px;
  }
  .view_monitor:focus, .view_monitor:hover {
    color: #095f8a;
  }
  .view_monitor:active, .view_monitor:hover {
    outline: 0;
  }
  .view_monitor{
    color: #0e90d2;
    cursor: pointer;
  }
  .qtable-style .vxe-body--row .row-red {
    /*  background-color: #2db7f5;*/
    color: red !important;
  }
  .qtable-style .vxe-body--row .row-orange {
    /*  background-color: #2db7f5;*/
    color: orange;
  }
}

</style>
